import React from "react"
import styled from "styled-components"

import { MainTitle } from "../global/common/text"
import { GrayContainer, LineWrapper } from "../global/common/containers"
import { FadedGreenBigBox } from "../global/common/ornaments"

import MobileMockup from "./common/MobileMockup"
import Points from "../global/Points"

import SiteLanguage from "../../helpers/siteLanguage"
import optimizedPath from "../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../helpers/getRangedSrcSet"

const PointsWrapper = styled(LineWrapper)`
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);

  margin-bottom: var(--section-spacing);
`

const PointsContent = styled.article`
  width: 100%;
`

const PointsMockup = styled(FadedGreenBigBox)`
  &::before {
    --box-height: 620px;

    top: calc(var(--section-spacing) * -1);
    left: -1px;
  }
`

const ImportantPoints = ({ data }) => {
  const header = data.csImportantPointsTitle
  const points = data.csImportantPoints
  const image = {
    url: getImageBySrcSetAndSize(data.csImportantPointsMockup?.srcSet, 'medium') ? getImageBySrcSetAndSize(data.csImportantPointsMockup?.srcSet, 'medium') : optimizedPath(data.csImportantPointsMockup?.sourceUrl),
    srcSet: getRangedSrcSet(data.csImportantPointsMockup?.srcSet, 'medium'),
    alt: (SiteLanguage() !== "en" && data.csImportantPointsMockup?.atttachement_pl?.altTextPl) ? data.csImportantPointsMockup?.atttachement_pl?.altTextPl : data.csImportantPointsMockup?.altText
  }

  return (
    <GrayContainer>
      <PointsWrapper flex>
        <PointsContent>
          <MainTitle
            dangerouslySetInnerHTML={{ __html: header }}
            style={{
              "--padding-bottom": "calc(var(--section-spacing) * 0.7)",
            }}
          />
          <Points points={points} />
        </PointsContent>
        {image.url && (
          <PointsMockup>
            <MobileMockup url={image.url} srcSet={image.srcSet} alt={image.alt} />
          </PointsMockup>
        )}
      </PointsWrapper>
    </GrayContainer>
  )
}

export default ImportantPoints
