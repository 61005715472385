import React, { useState } from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import { CenterTitle } from "../global/common/text"
import { LineWrapper } from "../global/common/containers"

import { GalleryInner } from "../global/gallery/common"
import ClassicImage from "../global/gallery/ClassicImage"
import GreenImage from "../global/gallery/GreenImage"
import ImagesLightbox from "../global/gallery/ImagesLightbox"

import SiteLanguage from "../../helpers/siteLanguage"
import optimizedPath from "../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../helpers/getRangedSrcSet"

const GalleryWrapper = styled(LineWrapper)`
  padding-bottom: calc(120px + var(--section-spacing));

  @media ${maxD.tablet} {
    padding-bottom: calc(var(--section-spacing) / 2);
  }
`

const Gallery = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isLightboxVisible, setLightboxVisibility] = useState(false)

  const header = data.csGalleryTitle
  const gallery = data.csGallery

  const handleImageClick = (event, i) => {
    event.preventDefault()

    setLightboxVisibility(true)
    setCurrentSlide(i)
  }

  return (
    <GalleryWrapper style={{ zIndex: "inherit" }}>
      <CenterTitle
        dangerouslySetInnerHTML={{ __html: header }}
        style={{ maxWidth: "100%", "--padding-bottom": "100px" }}
      />
      <GalleryInner className="classic">
        {gallery &&
          gallery.map((galleryItem, i) => {
            const id = galleryItem.id

            const itemData = {
              url: getImageBySrcSetAndSize(galleryItem?.srcSet, 'medium') ? getImageBySrcSetAndSize(galleryItem?.srcSet, 'medium') : optimizedPath(galleryItem?.sourceUrl),
              fullUrl: getImageBySrcSetAndSize(galleryItem?.srcSet),
              srcSet: getRangedSrcSet(galleryItem?.srcSet, 'medium'),
              alt: (SiteLanguage() !== "en" && galleryItem?.atttachement_pl?.altTextPl) ? galleryItem?.atttachement_pl?.altTextPl : galleryItem.aboutGalleryImage?.altText,
            }

            const getGalleryElement = elementType => {
              switch (elementType) {
                case "greenImage":
                  return (
                    <GreenImage
                      onImageLinkClick={event => handleImageClick(event, i)}
                      key={`small-${id}`}
                      {...itemData}
                    />
                  )
                default:
                  return (
                    <ClassicImage
                      onImageLinkClick={event => handleImageClick(event, i)}
                      key={`small-${id}`}
                      {...itemData}
                    />
                  )
              }
            }

            let elementType

            if (i === 1 || i === 6 || i === 10) {
              elementType = "greenImage"
            } else {
              elementType = "classicImage"
            }

            return getGalleryElement(elementType)
          })}
      </GalleryInner>
      <ImagesLightbox
        slides={gallery}
        currentSlide={currentSlide}
        isLightboxVisible={isLightboxVisible}
        setLightboxVisibility={setLightboxVisibility}
      />
    </GalleryWrapper>
  );
}

export default Gallery
