import React from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import { MainTitle } from "../global/common/text"
import { LineWrapperWithReversedSpace } from "../global/common/containers"

import MobileMockup from "./common/MobileMockup"
import GrayBox from "./common/GrayBox"
import MasonryBoxes from "../global/atoms/MasonryBoxes"

import SiteLanguage from "../../helpers/siteLanguage"
import optimizedPath from "../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../helpers/getRangedSrcSet"

const EffectsTitle = styled(MainTitle)`
  margin-bottom: 3rem;

  @media ${maxD.laptop} {
    margin-bottom: 2rem;
  }
`

const EffectsContent = styled.div`
  width: 100%;
  margin-bottom: 190px;

  @media ${maxD.tablet} {
    margin-bottom: calc(var(--section-spacing) * 0.6);
  }
`

const Effects = ({ data }) => {
  const image = {
    url: getImageBySrcSetAndSize(data.csEffectsMockup?.srcSet, 'medium') ? getImageBySrcSetAndSize(data.csEffectsMockup?.srcSet, 'medium') : optimizedPath(data.csEffectsMockup?.sourceUrl),
    srcSet: getRangedSrcSet(data.csEffectsMockup?.srcSet, 'medium'),
    alt: (SiteLanguage() !== "en" && data.csEffectsMockup?.atttachement_pl?.altTextPl) ? data.csEffectsMockup?.atttachement_pl?.altTextPl : data.csEffectsMockup?.altText
  }

  const header = data.csEffectsTitle
  const grayBoxContent = data.csEffectsList

  const boxes = data.csEffects

  return (
    <LineWrapperWithReversedSpace flex spaceBetween>
      {image.url && <MobileMockup url={image.url} srcSet={image.srcSet} alt={image.alt} />}
      <EffectsContent>
        <EffectsTitle dangerouslySetInnerHTML={{ __html: header }} />
        {grayBoxContent && <GrayBox data={grayBoxContent} />}
        <MasonryBoxes
          boxes={boxes}
          boxStyles={{
            backgroundColor: "#fff",
          }}
          boxesContainerStyles={{
            "--container-top": "0",
            "--container-left-margin-laptop": 0,
            "--container-left-margin-desktop": 0,
            "--line-content": "none",
          }}
        />
      </EffectsContent>
    </LineWrapperWithReversedSpace>
  )
}

export default Effects
