import React from "react"
import styled from "styled-components"

import { maxD } from "../../../assets/styles/helpers/devices"

export const Box = styled.article`
  padding: 50px;
  margin: 0 0 60px;

  background-color: var(--light-gray);
  border: 1px solid var(--border-green);

  @media ${maxD.tabletL} {
    margin: 0 0 40px;
  }

  @media ${maxD.mobileL} {
    padding: 30px;
  }
`

const GrayBox = ({ data }) => {
  return <Box className="content" dangerouslySetInnerHTML={{ __html: data }} />
}

export default GrayBox
