import React from "react"
import styled from "styled-components"
import LiteYouTubeEmbed from "react-lite-youtube-embed"

import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { LineWrapper } from "../global/common/containers"
import { SecondaryTitle } from "../global/common/text"

const MovieWrapper = styled(LineWrapper)`
  padding-bottom: var(--section-spacing);

  @media ${maxD.tabletL} {
    flex-wrap: wrap;
  }

  @media ${maxD.tablet} {
    padding-top: calc(var(--section-spacing) * 0.5);
  }
`

const MoviePlayer = styled.figure`
  width: min(700px, 40vw);
  flex-shrink: 0;

  @media ${minD.tabletL} {
    margin-right: calc(var(--section-spacing) * 0.6);
  }

  @media ${maxD.tabletL} {
    width: 100%;
    margin-bottom: calc(var(--section-spacing) * 0.6);
  }
`

const MovieDescription = styled.article``

const Movie = ({ data }) => {
  const header = data.csMovieTitle
  const description = data.csMovieDescription
  const movieID = data.csYtMovie

  return (
    <MovieWrapper flex alignItems="c">
      <MoviePlayer>
        <LiteYouTubeEmbed
          id={movieID}
          poster="maxresdefault"
          title="Client words on video"
          noCookie={true}
        />
      </MoviePlayer>
      <MovieDescription>
        <SecondaryTitle dangerouslySetInnerHTML={{ __html: header }} />
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </MovieDescription>
    </MovieWrapper>
  )
}

export default Movie
