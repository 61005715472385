import React from "react"
import styled from "styled-components"
// import { Link as LinkTo } from "gatsby"

import Arrow from "../../assets/images/svg/right-arrow.svg"

import { LineWrapper } from "../global/common/containers"
import { UnderlinedLink } from "../global/common/links"
// import { arrow } from "../global/common/ornaments"

import SiteLanguage from "../../helpers/siteLanguage"

const NavWrapper = styled(LineWrapper)`
  padding: calc(var(--section-spacing) * 0.15) 0
    calc(var(--section-spacing) * 0.7);
`

// commented for now, TBD in the future
// const NavItem = styled(LinkTo)`
//   ${arrow}

//   &:first-child {
//     transform: rotate(180deg);
//   }
// `

const EndingNav = () => {
  return (
    <NavWrapper
      spaceBetween
      flex
      alignItems="c"
      as="nav"
      style={{
        "--does-bold-line-exists": "none",
      }}
    >
      {/* <NavItem aria-label={ SiteLanguage === "en" ? "Previous case study" : "Poprzednie case study" } to="#" /> */}
      <UnderlinedLink to={ SiteLanguage === "en" ? "https://bravenew.agency/case-studies" : "https://bravenew.agency/pl/case-studies-nasze-projekty" }>
        Case studies
        <Arrow />
      </UnderlinedLink>
      {/* <NavItem aria-label={ SiteLanguage === "en" ? "Next case study" : "Następne case study" } to="#" /> */}
    </NavWrapper>
  )
}

export default EndingNav
