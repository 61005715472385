import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { maxD } from "../../../assets/styles/helpers/devices"

import { noAppearance, ornament } from "../../../assets/styles/helpers/common"
import { Overlay } from "../../global/common/ornaments"

const BeforeAndAfterContent = styled.figure`
  --images-height: calc(var(--section-spacing) * 5.2);

  position: relative;

  width: calc(100% - (var(--section-spacing) * 1.5));
  height: var(--images-height);

  margin: 0 auto;

  @media ${maxD.tabletL} {
    width: 100%;
  }
`

const SliderContainer = styled.span`
  --arrow-container-dimension: 40px;
  --left: 50%;

  position: absolute;

  width: 100%;
  height: 100%;

  &::after,
  &::before {
    ${ornament}

    width: var(--arrow-container-dimension);
    height: var(--arrow-container-dimension);
    z-index: 9;

    top: 50%;
    border-radius: 50%;

    background: var(--icon-arrow-background);
    background-size: 15px;
    background-color: var(--main-green);
  }

  &::before {
    left: calc(var(--left) - var(--arrow-container-dimension) - 12px);
    transform: rotate(180deg) translateY(20px);
  }

  &::after {
    left: calc(var(--left) + 12px);
    transform: translateY(-20px);
  }
`

const Slider = styled.input`
  background-color: transparent;

  position: absolute;

  width: 100%;
  height: 100%;

  top: -2px;
  left: -2px;
  z-index: 8;

  &::-webkit-slider-thumb {
    ${noAppearance}
    position: relative;

    width: 4px;
    height: var(--images-height, 500px);

    cursor: ew-resize;
    background-color: var(--main-green);
  }

  &::-moz-range-thumb {
    width: 4px;
    height: var(--images-height, 500px);

    cursor: ew-resize;
    background-color: var(--main-green);

    border: none;
    border-radius: 0;
  }
`

const BeforeAndAfterSlider = ({ before, after }) => {
  const [revealStep, updateRevealStep] = useState(50)
  const [imagesWidth, setImagesWithSameAsContainer] = useState("1061px")

  const imagesContainer = useRef(null)

  useEffect(() => {
    const imagesContainerHeight = imagesContainer.current.offsetWidth
    setImagesWithSameAsContainer(imagesContainerHeight)
  }, [])

  return (
    <BeforeAndAfterContent ref={imagesContainer}>
      {/* <Overlay
        image={before.url}
        alt={before.alt}
        style={{
          position: "absolute",
          zIndex: "2",
        }}
        imgStyle={{
          objectPosition: "top left",
          width: `${revealStep}%`,
        }}
      /> */}
      <div style={{ width: `${revealStep}%`, position: "absolute", overflow: "hidden", zIndex: "2", height: "100%" }}>
        <img
          loading="lazy"
          src={before.url}
          srcSet={before.srcSet}
          alt={before.alt} 
          style={{ width: imagesWidth, height: "100%", position: "absolute", top: "0", left: "0" }}
        />
      </div>
      <SliderContainer style={{ "--left": `${revealStep}%` }}>
        <Slider
          type="range"
          max="100"
          onChange={event => updateRevealStep(event.target.value)}
        />
      </SliderContainer>
      <Overlay
        loading="lazy"
        src={after.url}
        srcSet={after.srcSet}
        alt={after.alt}
        style={{
          position: "absolute",
          zIndex: "1",
        }}
        imgStyle={{
          objectPosition: "top",
          width: imagesWidth,
        }}
      />
    </BeforeAndAfterContent>
  )
}

export default BeforeAndAfterSlider
