import React from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import { SecondaryTitle } from "../global/common/text"
import { LineWrapper } from "../global/common/containers"
import { Signature } from "../global/atoms/Signature"
import { EqualBox, EqualBoxes } from "./common/boxes"

import Avatar from "../global/atoms/Avatar"

const Member = styled(EqualBox)`
  width: 50%;
  padding: 0;

  @media ${maxD.tabletL} {
    width: 100%;
  }
`

const MemberContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  padding: 2.8rem;
`

const Executors = ({ data }) => {
  const title = data.csExecutorsTitle
  const team = data.csExecutors

  return (
    <LineWrapper
      style={{
        "--does-bold-line-exists": "none",
      }}
    >
      <SecondaryTitle
        dangerouslySetInnerHTML={{ __html: title }}
        style={{ "--spacing-bottom": "45px" }}
      />
      <EqualBoxes
        style={{
          "--dots-appearance": "none",
        }}
      >
        {team &&
          team.map((member, i) => {
            if (!member.csExecutorPerson) return null

            const id = member.csExecutorPerson.id

            const name = member.csExecutorPerson.acfTeamMember?.singleUserName

            const role = member.csExecutorRole

            const image = {
              url: member.csExecutorPerson.acfTeamMember?.singleUserImage
                ?.localFile.childImageSharp.gatsbyImageData,
              alt: member.csExecutorPerson.acfTeamMember?.singleUserImage
                ?.altText,
            }

            return (
              <Member key={`${id}-${i}`}>
                <MemberContainer>
                  {image.url && (
                    <Avatar
                      image={image.url}
                      alt={image.alt}
                      imgStyle={{ borderRadius: "50%" }}
                    />
                  )}
                  <Signature
                    name={name}
                    position={role}
                    style={{ padding: "0" }}
                  />
                </MemberContainer>
              </Member>
            )
          })}
      </EqualBoxes>
    </LineWrapper>
  )
}

export default Executors
