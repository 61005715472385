import React from "react"
import styled from "styled-components"

import { maxD } from "../../../assets/styles/helpers/devices"

const Mockup = styled.figure`
  width: 550px;
  flex-shrink: 0;
  min-height: 100%;

  padding: 100px 65px;

  @media ${maxD.laptopL} {
    width: 380px;
  }

  @media ${maxD.tabletL} {
    display: none;
  }
`

const MockupImage = styled.img`
  top: 150px;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0 auto;
  max-width: none;
  padding: 0;
  right: 0;
  width: 100%;
  object-fit: contain;
  opacity: 1;

  max-height: calc(100vh - 200px);
`

const MobileMockup = ({ url, srcSet, alt }) => {
  return (
    <Mockup>
      <MockupImage
        loading="lazy"
        src={url}
        srcSet={srcSet}
        alt={alt}
        objectFit="contain"
        style={{ position: "sticky" }}
      />
    </Mockup>
  )
}

export default MobileMockup
