import React from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import { SecondaryTitle } from "../global/common/text"
import { LineWrapper } from "../global/common/containers"
import { EqualBox, EqualBoxes } from "./common/boxes"

const ScopeWrapper = styled(LineWrapper)`
  padding-top: 4.5rem;
`

const ScopeElement = styled(EqualBox)`
  width: 25%;
  padding: 2rem 1.5rem 2rem 4rem;

  background-color: var(--ice-gray);

  &::before {
    top: calc(50% - calc(var(--dot-size) * 0.5));
    left: 2rem;
  }

  @media ${maxD.laptopL} {
    width: 50%;
  }

  @media ${maxD.tabletL} {
    padding: 1.5rem 2rem 1.5rem 4.3rem;
  }

  @media ${maxD.tablet} {
    width: 100%;
    padding: 2rem 2rem 2rem 4.3rem;
  }
`

const Scope = ({ data }) => {
  const title = data.csScopeTitle
  const list = data.csScope

  return (
    <ScopeWrapper>
      <SecondaryTitle
        dangerouslySetInnerHTML={{ __html: title }}
        style={{ "--spacing-bottom": "45px" }}
      />
      <EqualBoxes>
        {list &&
          list.map((item, i) => {
            const description = item.csScopeDescription

            return (
              <ScopeElement
                key={`scope-${i}`}
                dangerouslySetInnerHTML={{ __html: description }}
              ></ScopeElement>
            )
          })}
      </EqualBoxes>
    </ScopeWrapper>
  )
}

export default Scope
