import React from "react"
import styled from "styled-components"

import { LineWrapper } from "../global/common/containers"
import { CenterTitle } from "../global/common/text"
import BeforeAndAfterSlider from "./common/BeforeAndAfterSlider"

import SiteLanguage from "../../helpers/siteLanguage"
import optimizedPath from "../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../helpers/getRangedSrcSet"

const BeforeAndAfterWrapper = styled(LineWrapper)`
  padding-bottom: var(--section-spacing);
`

const BeforeAndAfter = ({ data }) => {
  const header = data.csBeforeAfterTitle

  const before = {
    url: getImageBySrcSetAndSize(data.csBefore.srcSet) ? getImageBySrcSetAndSize(data.csBefore.srcSet) : optimizedPath(data.csBefore.sourceUrl),
    srcSet: getRangedSrcSet(data.csBefore.srcSet),
    alt: (SiteLanguage() !== "en" && data.csBefore.atttachement_pl?.altTextPl) ? data.csBefore.atttachement_pl?.altTextPl : data.csBefore.altText
  }

  const after = {
    url: getImageBySrcSetAndSize(data.csAfter.srcSet) ? getImageBySrcSetAndSize(data.csAfter.srcSet) : optimizedPath(data.csAfter.sourceUrl),
    srcSet: getRangedSrcSet(data.csAfter.srcSet),
    alt: (SiteLanguage() !== "en" && data.csAfter.atttachement_pl?.altTextPl) ? data.csAfter.atttachement_pl?.altTextPl : data.csAfter.altText
  }

  return (
    <BeforeAndAfterWrapper>
      <CenterTitle
        dangerouslySetInnerHTML={{ __html: header }}
        style={{ "--padding-bottom": "4.5rem", "--padding-top": "4.5rem" }}
      />
      <BeforeAndAfterSlider before={before} after={after} />
    </BeforeAndAfterWrapper>
  )
}

export default BeforeAndAfter
